export const CarsModels = [
  { model: "BMX", price: 403, category: "URBAIN" },
  { model: "Bmw E34", price: 605500, category: "PRESTIGE" },
  { model: "Fixter", price: 700, category: "URBAIN" },
  { model: "Scorcher", price: 816, category: "URBAIN" },
  { model: "Tri bike", price: 1166, category: "URBAIN" },
  { model: "Faggio", price: 3500, category: "URBAIN" },
  { model: "Clique", price: 9916, category: "URBAIN" },
  { model: "Ratbike", price: 10063, category: "URBAIN" },
  { model: "Sanchez", price: 10063, category: "URBAIN" },
  { model: "Defiler", price: 10733, category: "URBAIN" },
  { model: "Sanchez sport", price: 10763, category: "URBAIN" },
  { model: "Blazer", price: 11673, category: "URBAIN" },
  { model: "Tornado Rouillé", price: 12075, category: "URBAIN" },
  { model: "Blazer Sport", price: 14088, category: "URBAIN" },
  { model: "Paradise", price: 15400, category: "URBAIN" },
  { model: "Panto", price: 16080, category: "URBAIN" },
  { model: "Manchez", price: 16100, category: "URBAIN" },
  { model: "Enduro", price: 16905, category: "URBAIN" },
  { model: "Retinue", price: 17308, category: "URBAIN" },
  { model: "Sovereign", price: 18113, category: "URBAIN" },
  { model: "Tornado", price: 18113, category: "URBAIN" },
  { model: "Surfer", price: 18113, category: "URBAIN" },
  { model: "Surfer Vintage", price: 15113, category: "URBAIN" },
  { model: "Tornado Old", price: 19320, category: "URBAIN" },
  { model: "Blista", price: 19702, category: "URBAIN" },
  { model: "Tornado Trail", price: 20125, category: "URBAIN" },
  { model: "Asea", price: 20930, category: "URBAIN" },
  { model: "Regina", price: 20930, category: "URBAIN" },
  { model: "Emperor", price: 21735, category: "URBAIN" },
  { model: "Tornado Decap", price: 22137, category: "URBAIN" },
  { model: "Bodhi", price: 22138, category: "URBAIN" },
  { model: "Glandale", price: 22138, category: "URBAIN" },
  { model: "Glandale Old", price: 44138, category: "URBAIN" },
  { model: "Asterope", price: 22138, category: "URBAIN" },
  { model: "Fagaloa", price: 22138, category: "URBAIN" },
  { model: "Esskey", price: 23345, category: "URBAIN" },
  { model: "Daemon High", price: 23345, category: "URBAIN" },
  { model: "wolfsbane", price: 23345, category: "URBAIN" },
  { model: "paradise", price: 25400, category: "URBAIN" },
  { model: "FCR 1000", price: 23748, category: "URBAIN" },
  { model: "PCJ", price: 23748, category: "URBAIN" },
  { model: "Issi", price: 24130, category: "URBAIN" },
  { model: "rhapsody", price: 24130, category: "URBAIN" },
  { model: "Hexer", price: 24150, category: "URBAIN" },
  { model: "Thrust", price: 24150, category: "URBAIN" },
  { model: "Vader", price: 24553, category: "URBAIN" },
  { model: "Ingot", price: 24553, category: "URBAIN" },
  { model: "Daemon", price: 24955, category: "URBAIN" },
  { model: "Carbon RS", price: 24955, category: "URBAIN" },
  { model: "Cliffhanger", price: 25358, category: "URBAIN" },
  { model: "FCR 1000 Custom", price: 25614, category: "URBAIN" },
  { model: "Innovation", price: 25760, category: "URBAIN" },
  { model: "Premier", price: 25760, category: "URBAIN" },
  { model: "Warrener", price: 25760, category: "URBAIN" },
  { model: "Nightblade", price: 26163, category: "URBAIN" },
  { model: "BF 400", price: 26163, category: "URBAIN" },
  { model: "Bagger", price: 26163, category: "URBAIN" },
  { model: "Nemesis", price: 26163, category: "URBAIN" },
  { model: "Ratloader", price: 26163, category: "URBAIN" },
  { model: "Primo Custom", price: 26163, category: "URBAIN" },
  { model: "Intruder", price: 26163, category: "URBAIN" },
  { model: "Peyote", price: 40250, category: "URBAIN" },
  { model: "Double", price: 26968, category: "URBAIN" },
  { model: "Chimera", price: 27370, category: "URBAIN" },
  { model: "Lectro", price: 27370, category: "URBAIN" },
  { model: "Vortex", price: 27370, category: "URBAIN" },
  { model: "Pigalle", price: 27370, category: "URBAIN" },
  { model: "Rebel Lowcoast", price: 27854, category: "URBAIN" },
  { model: "Ruffian", price: 28980, category: "URBAIN" },
  { model: "Gargoyle", price: 29383, category: "URBAIN" },
  { model: "sandking 3 portes", price: 29516, category: "URBAIN" },
  { model: "Burrito", price: 29546, category: "URBAIN" },
  { model: "Stratum", price: 29785, category: "URBAIN" },
  { model: "Avarus", price: 30188, category: "URBAIN" },
  { model: "Diablous", price: 30188, category: "URBAIN" },
  { model: "Kalahari", price: 30188, category: "URBAIN" },
  { model: "Rancher", price: 30188, category: "URBAIN" },
  { model: "Issi Derby", price: 31395, category: "URBAIN" },
  { model: "Diablous Road", price: 31798, category: "URBAIN" },
  { model: "zombiea", price: 31798, category: "URBAIN" },
  { model: "Prairie", price: 32180, category: "URBAIN" },
  { model: "Dloader", price: 32200, category: "URBAIN" },
  { model: "Zombie Luxuary", price: 33005, category: "URBAIN" },
  { model: "Sanctus", price: 33408, category: "URBAIN" },
  { model: "Staffhord", price: 33810, category: "URBAIN" },
  { model: "Slamvan S", price: 54215, category: "URBAIN" },
  { model: "Rebel", price: 34213, category: "URBAIN" },
  { model: "sandking xl 5 portes", price: 34213, category: "URBAIN" },
  { model: "Stanier", price: 34213, category: "URBAIN" },
  { model: "Camper", price: 34213, category: "URBAIN" },
  { model: "Washington", price: 34615, category: "URBAIN" },
  { model: "Rumpo Trail", price: 35018, category: "URBAIN" },
  { model: "speedo", price: 35018, category: "URBAIN" },
  { model: "rumpo", price: 35018, category: "URBAIN" },
  { model: "pony", price: 35420, category: "URBAIN" },
  { model: "Vindicator", price: 35823, category: "URBAIN" },
  { model: "bati", price: 36225, category: "URBAIN" },
  { model: "rumpo D", price: 37450, category: "URBAIN" },
  { model: "pony S", price: 37450, category: "URBAIN" },
  { model: "Blista C", price: 37916, category: "URBAIN" },
  { model: "Akuma", price: 38238, category: "URBAIN" },
  { model: "Bati 801RR", price: 38238, category: "URBAIN" },
  { model: "Ruiner", price: 38238, category: "URBAIN" },
  { model: "Slamvan rallongé", price: 38238, category: "URBAIN" },
  { model: "Cheburek", price: 13538, category: "URBAIN" },
  { model: "Journey", price: 38238, category: "URBAIN" },
  { model: "bobcat xl", price: 38238, category: "URBAIN" },
  { model: "Brioso", price: 39664, category: "URBAIN" },
  { model: "dilettante", price: 39827, category: "URBAIN" },
  { model: "youga", price: 40174, category: "URBAIN" },
  { model: "Michelli", price: 40250, category: "URBAIN" },
  { model: "minivan", price: 40250, category: "URBAIN" },
  { model: "speedo", price: 42116, category: "URBAIN" },
  { model: "Voodoo", price: 42263, category: "URBAIN" },
  { model: "Burrito Gang", price: 43582, category: "URBAIN" },
  { model: "Virgo Classic", price: 43516, category: "URBAIN" },
  { model: "Blade", price: 44275, category: "URBAIN" },
  { model: "Yosemite", price: 44275, category: "URBAIN" },
  { model: "minivan Trail", price: 44916, category: "URBAIN" },
  { model: "Buccaneer", price: 46288, category: "URBAIN" },
  { model: "Chino", price: 46288, category: "URBAIN" },
  { model: "Picador", price: 46288, category: "URBAIN" },
  { model: "Impaler", price: 46783, category: "URBAIN" },
  { model: "Buccaneer Rider", price: 48300, category: "URBAIN" },
  { model: "Chino Luxe", price: 48300, category: "URBAIN" },
  { model: "Virgo", price: 48516, category: "URBAIN" },
  { model: "Tulip", price: 48300, category: "URBAIN" },
  { model: "freecrawler", price: 48300, category: "URBAIN" },
  { model: "Bison", price: 48300, category: "URBAIN" },
  { model: "Hakuchou", price: 50313, category: "URBAIN" },
  { model: "Faction", price: 50313, category: "URBAIN" },
  { model: "Stalion", price: 50313, category: "URBAIN" },
  { model: "Vamos", price: 50313, category: "URBAIN" },
  { model: "Faction Rider", price: 52325, category: "URBAIN" },
  { model: "Tampa", price: 52325, category: "URBAIN" },
  { model: "Sabre Turbo", price: 54338, category: "URBAIN" },
  { model: "Surge", price: 54338, category: "URBAIN" },
  { model: "Sabre GT", price: 56350, category: "URBAIN" },
  { model: "Bifta", price: 58363, category: "URBAIN" },
  { model: "Lurcher", price: 59185, category: "URBAIN" },
  { model: "Hakuchou Sport", price: 60375, category: "URBAIN" },
  { model: "Vigero", price: 60375, category: "URBAIN" },
  { model: "Dune Buggy", price: 60375, category: "URBAIN" },
  { model: "Hustler", price: 64400, category: "URBAIN" },
  { model: "Moonbeam", price: 64400, category: "URBAIN" },
  { model: "Phoenix", price: 66413, category: "URBAIN" },
  { model: "Tailgater", price: 67229, category: "URBAIN" },
  { model: "Dukes", price: 78488, category: "LUXE" },
  { model: "Faction XL", price: 78488, category: "LUXE" },
  { model: "Hotknife", price: 78488, category: "LUXE" },
  { model: "Habanero", price: 78488, category: "LUXE" },
  { model: "Raptor", price: 80500, category: "LUXE" },
  { model: "Dominator Sport", price: 81666, category: "LUXE" },
  { model: "Ellie", price: 82500, category: "LUXE" },
  { model: "Btype", price: 86538, category: "LUXE" },
  { model: "Manana", price: 86538, category: "LUXE" },
  { model: "Huntley S", price: 88550, category: "LUXE" },
  { model: "cavalcade", price: 78783, category: "LUXE" },
  { model: "Mesa", price: 88813, category: "LUXE" },
  { model: "Gresley", price: 90563, category: "LUXE" },
  { model: "Riata", price: 94588, category: "LUXE" },
  { model: "cavalcade New", price: 74588, category: "LUXE" },
  { model: "Gauntlet", price: 98613, category: "LUXE" },
  { model: "Gauntlet C", price: 115000, category: "LUXE" },
  { model: "Gauntlet Sport", price: 95215, category: "LUXE" },
  { model: "Gauntlet X", price: 125124, category: "LUXE" },
  { model: "Moonbeam Rider", price: 98613, category: "LUXE" },
  { model: "Mesa Trail", price: 98613, category: "LUXE" },
  { model: "BeeJay XL", price: 98613, category: "LUXE" },
  { model: "Nightshade", price: 100625, category: "LUXE" },
  { model: "Fugitive", price: 100625, category: "LUXE" },
  { model: "FQ2", price: 100625, category: "LUXE" },
  { model: "Dominator Deluxe", price: 105000, category: "LUXE" },
  { model: "Rocoto", price: 106663, category: "LUXE" },
  { model: "Patriot", price: 110688, category: "LUXE" },
  { model: "Coquette C", price: 112700, category: "LUXE" },
  { model: "Dubsta", price: 114713, category: "LUXE" },
  { model: "Granger", price: 114713, category: "LUXE" },
  { model: "XLS", price: 118738, category: "LUXE" },
  { model: "Dubsta Luxe", price: 119151, category: "LUXE" },
  { model: "Baller Sport", price: 120750, category: "LUXE" },
  { model: "Baller C", price: 110000, category: "LUXE" },
  { model: "Baller", price: 115250, category: "LUXE" },
  { model: "Deviant", price: 120160, category: "LUXE" },
  { model: "Cog 55", price: 130813, category: "LUXE" },
  { model: "GT 500", price: 134983, category: "LUXE" },
  { model: "Hermes", price: 138863, category: "LUXE" },
  { model: "Contender", price: 138863, category: "LUXE" },
  { model: "Cognoscenti", price: 140875, category: "LUXE" },
  { model: "Super Diamond", price: 140875, category: "LUXE" },
  { model: "Streiter", price: 140875, category: "LUXE" },
  { model: "Ruston", price: 140875, category: "LUXE" },
  { model: "Dominator", price: 142888, category: "LUXE" },
  { model: "Btype Luxe", price: 145833, category: "LUXE" },
  { model: "Rapid GT3", price: 159833, category: "LUXE" },
  { model: "Zion", price: 171063, category: "LUXE" },
  { model: "Sentinel", price: 171264, category: "LUXE" },
  { model: "Sentinel XS", price: 181045, category: "LUXE" },
  { model: "Zion Cabrio", price: 181125, category: "LUXE" },
  { model: "Oracle Classic", price: 181125, category: "LUXE" },
  { model: "Brawler", price: 181125, category: "LUXE" },
  { model: "Futo", price: 181125, category: "LUXE" },
  { model: "Trophy Truck", price: 195213, category: "LUXE" },
  { model: "Oracle Deluxe", price: 198333, category: "LUXE" },
  { model: "Turismo", price: 199238, category: "LUXE" },
  { model: "Cognoscenti Cab", price: 201250, category: "PRESTIGE" },
  { model: "felon", price: 203263, category: "PRESTIGE" },
  { model: "Tropos", price: 203263, category: "PRESTIGE" },
  { model: "Schwarzer", price: 227380, category: "PRESTIGE" },
  { model: "Btype Hotroad", price: 204166, category: "PRESTIGE" },
  { model: "Z190", price: 204166, category: "PRESTIGE" },
  { model: "Monroe", price: 205275, category: "PRESTIGE" },
  { model: "Stinger", price: 205275, category: "PRESTIGE" },
  { model: "F620", price: 206080, category: "PRESTIGE" },
  { model: "JB 700", price: 209300, category: "PRESTIGE" },
  { model: "Jackal", price: 211313, category: "PRESTIGE" },
  { model: "Schafter Old", price: 181375, category: "PRESTIGE" },
  { model: "Stinger GT", price: 211313, category: "PRESTIGE" },
  { model: "Felon GT", price: 214130, category: "PRESTIGE" },
  { model: "Windsor", price: 214130, category: "PRESTIGE" },
  { model: "Exemplar", price: 219363, category: "PRESTIGE" },
  { model: "Limousine - Stretch", price: 219363, category: "PRESTIGE" },
  { model: "Mamba", price: 219363, category: "PRESTIGE" },
  { model: "Windsor Drop", price: 221375, category: "PRESTIGE" },
  { model: "Dubsta 6x6", price: 221375, category: "PRESTIGE" },
  { model: "Schafter V12", price: 221375, category: "PRESTIGE" },
  { model: "Penumbra", price: 221375, category: "PRESTIGE" },
  { model: "Penumbra S", price: 271375, category: "PRESTIGE" },
  { model: "Stirling GT", price: 221375, category: "PRESTIGE" },
  { model: "Trophy Truck Limited", price: 221818, category: "PRESTIGE" },
  { model: "Guardian", price: 532330, category: "PRESTIGE" },
  { model: "Torero", price: 233546, category: "PRESTIGE" },
  { model: "Kuruma", price: 405463, category: "PRESTIGE" },
  { model: "Tampa Sport", price: 135463, category: "PRESTIGE" },
  { model: "Sultan", price: 241500, category: "PRESTIGE" },
  { model: "GB 200", price: 241500, category: "PRESTIGE" },
  { model: "Rapid GT", price: 241500, category: "PRESTIGE" },
  { model: "buffalo", price: 241500, category: "PRESTIGE" },
  { model: "Z-Type", price: 241500, category: "PRESTIGE" },
  { model: "Swinger", price: 241500, category: "PRESTIGE" },
  { model: "Omnis", price: 243513, category: "PRESTIGE" },
  { model: "Flash GT", price: 245350, category: "PRESTIGE" },
  { model: "Elegy", price: 245525, category: "PRESTIGE" },
  { model: "Rapid GT Cab", price: 247538, category: "PRESTIGE" },
  { model: "Elegy Classic", price: 251563, category: "PRESTIGE" },
  { model: "Alpha", price: 261625, category: "PRESTIGE" },
  { model: "Buffalo S", price: 261625, category: "PRESTIGE" },
  { model: "Buffalo Racer", price: 261625, category: "PRESTIGE" },
  { model: "Feltzer Sport", price: 273700, category: "PRESTIGE" },
  { model: "Feltzer", price: 221375, category: "PRESTIGE" },
  { model: "Surano", price: 275713, category: "PRESTIGE" },
  { model: "Furore GT", price: 275713, category: "PRESTIGE" },
  { model: "Fusilade", price: 279738, category: "PRESTIGE" },
  { model: "Cheetah C", price: 281750, category: "PRESTIGE" },
  { model: "Khamelion", price: 283360, category: "PRESTIGE" },
  { model: "Jester", price: 287788, category: "PRESTIGE" },
  { model: "Sentinel Classic", price: 289800, category: "PRESTIGE" },
  { model: "Bestia GTS", price: 289800, category: "PRESTIGE" },
  { model: "Jester Classic", price: 291813, category: "PRESTIGE" },
  { model: "Infernus Classic", price: 291813, category: "PRESTIGE" },
  { model: "Neo", price: 292133, category: "PRESTIGE" },
  { model: "Banshee", price: 301875, category: "PRESTIGE" },
  { model: "Schlagen", price: 301875, category: "PRESTIGE" },
  { model: "Verliere Sport", price: 301875, category: "PRESTIGE" },
  { model: "Casco", price: 301875, category: "PRESTIGE" },
  { model: "Coquette Sport", price: 309925, category: "PRESTIGE" },
  { model: "Specter Custom", price: 326938, category: "PRESTIGE" },
  { model: "Coquette Cab", price: 315963, category: "PRESTIGE" },
  { model: "Specter", price: 316162, category: "PRESTIGE" },
  { model: "Carbonizzare", price: 322000, category: "PRESTIGE" },
  { model: "Revolter", price: 323691, category: "PRESTIGE" },
  { model: "Lynx", price: 328038, category: "PRESTIGE" },
  { model: "Massacro", price: 331660, category: "PRESTIGE" },
  { model: "9F", price: 332063, category: "PRESTIGE" },
  { model: "Comet s", price: 332063, category: "PRESTIGE" },
  { model: "Seven 70", price: 332063, category: "PRESTIGE" },
  { model: "Massacro Racer", price: 581463, category: "PRESTIGE" },
  { model: "Raiden", price: 336088, category: "PRESTIGE" },
  { model: "9F Cabrio", price: 342125, category: "PRESTIGE" },
  { model: "Comet", price: 342125, category: "PRESTIGE" },
  { model: "Pariah", price: 344138, category: "PRESTIGE" },
  { model: "Italigto", price: 354200, category: "PRESTIGE" },
  { model: "Neon", price: 362250, category: "PRESTIGE" },
  { model: "Bullet", price: 422625, category: "PRESTIGE" },
  { model: "Banshee 900R", price: 503125, category: "PRESTIGE" },
  { model: "Infernus Sport", price: 321813, category: "PRESTIGE" },
  { model: "Voltic", price: 583625, category: "PRESTIGE" },
  { model: "Cheetah", price: 585638, category: "PRESTIGE" },
  { model: "SC1", price: 585638, category: "PRESTIGE" },
  { model: "Penetrator", price: 609788, category: "PRESTIGE" },
  { model: "Vacca", price: 623875, category: "PRESTIGE" },
  { model: "Entity XF", price: 623875, category: "PRESTIGE" },
  { model: "gp1", price: 623875, category: "PRESTIGE" },
  { model: "7B", price: 623875, category: "PRESTIGE" },
  { model: "Turismo R", price: 644000, category: "PRESTIGE" },
  { model: "Tempesta", price: 644000, category: "PRESTIGE" },
  { model: "Itali GTB", price: 662113, category: "PRESTIGE" },
  { model: "FMJ", price: 664125, category: "PRESTIGE" },
  { model: "Tyrus", price: 664125, category: "PRESTIGE" },
  { model: "Osiris", price: 661390, category: "PRESTIGE" },
  { model: "Autarch", price: 666516, category: "PRESTIGE" },
  { model: "Kamacho", price: 493000, category: "PRESTIGE" },
  { model: "Reaper", price: 693000, category: "PRESTIGE" },
  { model: "Pfister", price: 693875, category: "PRESTIGE" },
  { model: "T20", price: 704375, category: "PRESTIGE" },
  { model: "Italigtb Custom", price: 704375, category: "PRESTIGE" },
  { model: "Cyclone", price: 714000, category: "PRESTIGE" },
  { model: "Taipan", price: 518463, category: "PRESTIGE" },
  { model: "Tyrant", price: 724500, category: "PRESTIGE" },
  { model: "Nero", price: 648500, category: "PRESTIGE" },
  { model: "Devester", price: 732666, category: "PRESTIGE" },
  { model: "X80 Proto", price: 735000, category: "PRESTIGE" },
  { model: "Zentorno", price: 735000, category: "PRESTIGE" },
  { model: "Entity Super", price: 735000, category: "PRESTIGE" },
  { model: "Adder", price: 744625, category: "PRESTIGE" },
  { model: "Vagner", price: 744625, category: "PRESTIGE" },
  { model: "Nero Custom", price: 744625, category: "PRESTIGE" },
  { model: "Tezeract", price: 650500, category: "PRESTIGE" },
  { model: "Visione", price: 825125, category: "PRESTIGE" },
  { model: "Blista Custom", price: 24702, category: "URBAIN" },
  { model: "Drafter", price: 281375, category: "PRESTIGE" },
  { model: "Dukes Old", price: 77777, category: "LUXE" },
  { model: "Gautlet S", price: 95215, category: "LUXE" },
  { model: "Gautlet C", price: 115000, category: "LUXE" },
  { model: "Gaultet x", price: 125124, category: "LUXE" },
  { model: "Voodoo S", price: 37263, category: "URBAIN" },
  { model: "Yosemite S", price: 51254, category: "URBAIN" },
  { model: "Slamvan", price: 34215, category: "URBAIN" },
  { model: "Peyote R", price: 70542, category: "LUXE" },
  { model: "Ratloader R", price: 32512, category: "URBAIN" },
  { model: "Comet 4x4", price: 352063, category: "PRESTIGE" },
  { model: "Blazer Old", price: 12542, category: "URBAIN" },
  { model: "Freecrawler", price: 512125, category: "PRESTIGE" },
  { model: "Winky", price: 812125, category: "PRESTIGE" },
  { model: "Gauldale Old", price: 44138, category: "URBAIN" },
  { model: "Emperor Old", price: 16735, category: "URBAIN" },
  { model: "Primo", price: 16163, category: "URBAIN" },
  { model: "Locust", price: 483360, category: "PRESTIGE" },
  { model: "Paragon", price: 388238, category: "PRESTIGE" },
  { model: "Sultan RS", price: 358062, category: "PRESTIGE" },
  { model: "Issi Sport", price: 73063, category: "LUXE" },
  { model: "Imorgon", price: 302063, category: "PRESTIGE" },
  { model: "Schafter LWB", price: 225000, category: "PRESTIGE" },
  { model: "Jester R", price: 327788, category: "PRESTIGE" },
  { model: "Comet Old", price: 282063, category: "PRESTIGE" },
  { model: "Penulba S", price: 271375, category: "PRESTIGE" },
  { model: "Dynasty", price: 85451, category: "LUXE" },
  { model: "Manana S", price: 140538, category: "LUXE" },
  { model: "Nebula", price: 40275, category: "URBAIN" },
  { model: "Retinue S", price: 34308, category: "URBAIN" },
  { model: "Savestra", price: 36305, category: "URBAIN" },
  { model: "Infenus S", price: 321813, category: "PRESTIGE" },
  { model: "Tornado S", price: 21421, category: "URBAIN" },
  { model: "Tornado Old", price: 15421, category: "URBAIN" },
  { model: "Tornado Vintage", price: 16421, category: "URBAIN" },
  { model: "Tornado Cab", price: 125421, category: "LUXE" },
  { model: "Tornado D", price: 55421, category: "URBAIN" },
  { model: "Viseris", price: 72514, category: "LUXE" },
  { model: "zion S", price: 182542, category: "LUXE" },
  { model: "Emerus", price: 560018, category: "PRESTIGE" },
  { model: "Penetrator", price: 245125, category: "PRESTIGE" },
  { model: "Tyrus", price: 270455, category: "PRESTIGE" },
  { model: "Golfette", price: 40500, category: "URBAIN" },
  { model: "Tondeuse", price: 38500, category: "URBAIN" },
  { model: "Tracteur", price: 80500, category: "LUXE" },
  { model: "Camion Benne", price: 50650, category: "URBAIN" },
  { model: "Camion Militaire", price: 210000, category: "PRESTIGE" },
  { model: "Burrito X", price: 38238, category: "URBAIN" },
  { model: "Youga S", price: 52175, category: "URBAIN" },
  { model: "Youga X", price: 69069, category: "URBAIN" },
  { model: "Manchez R", price: 20100, category: "URBAIN" },
  { model: "Bati", price: 32238, category: "URBAIN" },
  { model: "Fcr", price: 28748, category: "URBAIN" },
  { model: "Stryder", price: 38748, category: "URBAIN" },
];
