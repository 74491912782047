export const OptionsTable = {
    motor: {
        1: {
            URBAIN: 0,
            LUXE: 0,
            PRESTIGE: 0,
        },
        2: {
            URBAIN: 0,
            LUXE: 0,
            PRESTIGE: 0,
        },
        3: {
            URBAIN: 0,
            LUXE: 0,
            PRESTIGE: 0,
        },
        4: {
            URBAIN: 14666,
            LUXE: 29333,
            PRESTIGE: 44000,
        },
        5: {
            URBAIN: 22000,
            LUXE: 44000,
            PRESTIGE: 66000,
        },
        6: {
            URBAIN: 25333,
            LUXE: 50666,
            PRESTIGE: 76000,
        },
    },
    suspension: {
        1: {
            URBAIN: 0,
            LUXE: 0,
            PRESTIGE: 0,
        },
        2: {
            URBAIN: 1666,
            LUXE: 3333,
            PRESTIGE: 5000,
        },
        3: {
            URBAIN: 2333,
            LUXE: 4666,
            PRESTIGE: 7000,
        },
        4: {
            URBAIN: 3000,
            LUXE: 6000,
            PRESTIGE: 9000,
        },
        5: {
            URBAIN: 3666,
            LUXE: 7333,
            PRESTIGE: 11000,
        },
        6: {
            URBAIN: 4333,
            LUXE: 8666,
            PRESTIGE: 13000,
        },
    },
    breaks: {
        1: {
            URBAIN: 0,
            LUXE: 0,
            PRESTIGE: 0,
        },
        2: {
            URBAIN: 3666,
            LUXE: 7333,
            PRESTIGE: 11000,
        },
        3: {
            URBAIN: 8000,
            LUXE: 11500,
            PRESTIGE: 24000,
        },
        4: {
            URBAIN: 11000,
            LUXE: 22000,
            PRESTIGE: 33000,
        },
        5: {
            URBAIN: 14666,
            LUXE: 29333,
            PRESTIGE: 44000,
        },
        6: {
            URBAIN: 18333,
            LUXE: 36666,
            PRESTIGE: 55000,
        },
    },
    transmission: {
        1: {
            URBAIN: 0,
            LUXE: 0,
            PRESTIGE: 0,
        },
        2: {
            URBAIN: 2000,
            LUXE: 4000,
            PRESTIGE: 6000,
        },
        3: {
            URBAIN: 3666,
            LUXE: 7333,
            PRESTIGE: 11000,
        },
        4: {
            URBAIN: 5666,
            LUXE: 11333,
            PRESTIGE: 20333,
        },
        5: {
            URBAIN: 8000,
            LUXE: 16000,
            PRESTIGE: 24000,
        },
        6: {
            URBAIN: 9833,
            LUXE: 19666,
            PRESTIGE: 29500,
        },
    },
    turbo: {
        true: {
            URBAIN: 18666,
            LUXE: 37333,
            PRESTIGE: 56000,
        },
        false: {
            URBAIN: 0,
            LUXE: 0,
            PRESTIGE: 0,
        }
    }
}
