import { Container, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import { CarsModels } from "../../DB/CarsModels";
import { OptionsTable } from "../../DB/OptionsTable";
import { fPrice, transformPrice } from "../../utils";
import { Logo } from "../Logo";
import "./Calculator.scss";
import { OptionSelector } from "./OptionSelector";
import ReactSelect from 'react-select';

const typesAsStrings = {
    buy: 'RACHAT',
    sell: 'REVENTE'
}

export const Calculator = ({ type }) => {
    const [selectedCar, setSelectedCar] = useState(CarsModels[0]);
    const [selectedOptions, setSelectedOptions] = useState({
        motor: 1,
        transmission: 1,
        breaks: 1,
        suspension: 1,
        turbo: false
    });

    const selectCar = (model) => {
        setSelectedCar(CarsModels.find(x => x.model === model));
    }

    const setOption = (option, value) => {
        setSelectedOptions({ ...selectedOptions, [option]: value });
    }

    const totalPrice = () => {
        let total = 0;

        total += transformPrice(type, selectedCar.price);

        for (const option of ['motor', 'transmission', 'breaks', 'suspension']) {
            total += transformPrice(type, OptionsTable[option][selectedOptions[option]][selectedCar.category]);
        }

        if (selectedOptions.turbo) {
            total += transformPrice(type, OptionsTable['turbo'][selectedOptions.turbo][selectedCar.category]);
        }

        return total;
    }

    return (
        <div className="calculator">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Typography align="center">
                            <Logo />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <h1>CALCULATEUR</h1>
                        <h2>{typesAsStrings[type]}</h2>

                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>Véhicule</Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={8}>
                                    <FormControl fullWidth>
                                        <ReactSelect
                                            options={CarsModels.map(item => ({ label: item.model}))}
                                            onChange={(e) => selectCar(e.label)}
                                            styles={{
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    'color': '#ddd',
                                                }),
                                                control: () => ({
                                                    '-webkit-align-items': 'center',
                                                    '-webkit-box-align': 'center',
                                                    '-ms-flex-align': 'center',
                                                    'align-items': 'center',
                                                    'color': '#ddd',
                                                    'background-color': 'transparent',
                                                    'border-color': 'rgba(0, 0, 0, .23)',
                                                    'border-radius': '4px',
                                                    'border-style': 'solid',
                                                    'border-width': '1px',
                                                    'cursor': 'default',
                                                    'display': '-webkit-box',
                                                    'display': '-webkit-flex',
                                                    'display': '-ms-flexbox',
                                                    'display': 'flex',
                                                    '-webkit-box-flex-wrap': 'wrap',
                                                    '-webkit-flex-wrap': 'wrap',
                                                    '-ms-flex-wrap': 'wrap',
                                                    'flex-wrap': 'wrap',
                                                    '-webkit-box-pack': 'justify',
                                                    '-webkit-justify-content': 'space-between',
                                                    'justify-content': 'space-between',
                                                    'min-height': '38px',
                                                    'outline': '0!important',
                                                    'position': 'relative',
                                                    '-webkit-transition': 'all 100ms',
                                                    'transition': 'all 100ms',
                                                    'box-sizing': 'border-box',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: '#ddd',
                                                    backgroundColor: '#666'
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    color: '#ddd',
                                                    backgroundColor: '#666'
                                                }),
                                                indicatorsContainer: (provided, state) => ({
                                                    ...provided,
                                                    color: '#ddd',
                                                    backgroundColor: '#666'
                                                }),
                                            }}
                                        />
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {selectedCar?.category ?? ''}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <hr />

                        <OptionSelector type={type} label="MOTEUR" value={selectedOptions['motor']} onChange={(e) => setOption("motor", e.target.value)} field="motor" items={[1, 2, 3, 4, 5, 6]} selectedCar={selectedCar} />
                        <OptionSelector type={type} label="TRANSMISSION" value={selectedOptions['transmission']} onChange={(e) => setOption("transmission", e.target.value)} field="transmission" items={[1, 2, 3, 4, 5, 6]} selectedCar={selectedCar} />
                        <OptionSelector type={type} label="FREIN" value={selectedOptions['breaks']} onChange={(e) => setOption("breaks", e.target.value)} field="breaks" items={[1, 2, 3, 4, 5, 6]} selectedCar={selectedCar} />
                        <OptionSelector type={type} label="SUSPENSION" value={selectedOptions['suspension']} onChange={(e) => setOption("suspension", e.target.value)} field="suspension" items={[1, 2, 3, 4, 5, 6]} selectedCar={selectedCar} />

                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>Turbo</Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={selectedOptions.turbo}
                                                onChange={(e) => setSelectedOptions({ ...selectedOptions, turbo: e.target.value })}
                                                classes={{ select: 'customSelector' }}
                                            >
                                                <MenuItem value={false}>Non</MenuItem>
                                                <MenuItem value={true}>Oui</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography align="right">
                                            {fPrice(transformPrice(type, OptionsTable['turbo'][selectedOptions.turbo][selectedCar.category]))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <hr />
                        
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>PRIX FINAL</Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography align="right">
                                            {fPrice(totalPrice())}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
