import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material"
import { OptionsTable } from "../../DB/OptionsTable";
import { fPrice, transformPrice } from '../../utils';

export const OptionSelector = ({ type, label, onChange, field, value, selectedCar, items = [] }) => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>{label}</Grid>
            <Grid item xs={12} md={8}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Select
                                value={value}
                                onChange={onChange}
                                classes={{ select: 'customSelector' }}
                            >
                                {items.map((item, i) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align="right">
                            {fPrice(transformPrice(type, OptionsTable[field][value][selectedCar.category]))}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
