export const fPrice = (price) => {
    return `${Math.round(price)} €`
}

const typesMultipliers = {
    buy: 0.6,
    sell: 0.8
}
export const transformPrice = (type, price) => {
    return parseInt(price) * typesMultipliers[type];
}
