import { Container, Grid } from '@mui/material';
import './App.scss';
import { Calculator } from './components/Calculator/Calculator';

function App() {
  return (
    <div className="App">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Calculator type="buy"></Calculator>
          </Grid>
          <Grid item xs={12} md={6}>
            <Calculator type="sell"></Calculator>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default App;
